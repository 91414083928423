<template>
  <v-container class="h-100">
    <v-row class="h-100 align-center" justify="center">
      <v-col cols="9" class="account-auth d-flex flex-column align-center">
        <img
          alt="logo"
          width="300"
          height="44.41"
          src="@/assets/imgs/account-auth-logo.svg"
          class="account-auth-logo"
        />

        <v-card class="auth-code-card" max-width="505">
          <v-card-title class="pt-0 pb-0 justify-center">
            <img
              alt="logo"
              src="@/assets/imgs/lock.svg"
              class="logo"
              width="125"
              height="116"
            />
          </v-card-title>

          <v-card-text class="m-auto">
            <h3 class="main-title">
              {{ $t("authenticate_your_account") }}
            </h3>
            <p class="main-body mb-0">
              {{ $t("account_auth_text", { authCodeLifeTime }) }}
            </p>
          </v-card-text>

          <v-card-actions class="justify-center pb-0">
            <v-form
              v-model="valid"
              ref="form"
              class="login_form"
              @submit.prevent="verifyCode"
            >
              <v-text-field
                v-model="form.code"
                :error-messages="authCodeErrorMsg"
                type="password"
                :rules="codeRules"
                class="auth_code"
                :label="$t('enter_your_code')"
                @input="authCodeErrorMsg = null"
              ></v-text-field>

              <div class="v-input__control d-flex flex-row justify-center">
                <v-btn
                  type="submit"
                  :disabled="!valid"
                  :loading="loading"
                  color="#6BC0A5"
                  class="mb-0 w-100 submit-btn"
                  @click="validate"
                >
                  {{ $t("enter") }}
                </v-btn>
              </div>

              <div class="v-input__control d-flex flex-row justify-center mt-5">
                <h4 class="cancel-btn" @click="logout">
                  {{ $t("cancel") }}
                </h4>
              </div>
            </v-form>
          </v-card-actions>
        </v-card>

        <v-flex class="resend-new-code justify-center">
          {{ $t("resend_new_code_text") }}
          <span class="action" :class="{'disable': resendDisable}" @click="sendCode">{{
            $t("resend_new_code")
          }}</span>
        </v-flex>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "account-auth",

  data() {
    return {
      authCodeLifeTime: null,
      authCodeErrorMsg: null,
      valid: false,
      loading: false,
      resendDisable:false,
      errMsg: null,
      form: {
        code: "",
      },
      codeRules: [(v) => !!v || this.$t("code_required")],
    };
  },

  beforeCreate() {
    if (this?.$store?.getters["auth/user"]?.is_two_factor_auth) {
      return this.$router
        .push({
          name: "members-list",
          params: {
            lang: this.$i18n.locale,
          },
        })
        .catch(() => {});
    }
  },

  mounted() {
    this.getAuthCodeLifeTime();

    this.$axios.post("dashboard/users/send-2fa-code", {
      codeGenerationLink: window.location.href,
    });

    this.$store.dispatch("logActivity", {
      tag: "account_auth",
      type: 1,
    });
  },

  destroyed() {
    this.$store.dispatch("logActivity", {
      tag: "account_auth",
      type: 2,
    });
  },

  methods: {
    ...mapActions({
      login: "auth/login",
      logoutAction: "auth/logout",
    }),
    logout() {
      this.$emit("hideDialog");

      this.logoutAction().then(() => {
        this.$router.replace({
          name: "login",
        });
      });
    },
    validate() {
      this.$refs.form.validate();
    },
    async sendCode() {
      this.resendDisable = true;

      await this.$axios
        .post("dashboard/users/send-2fa-code", {
          codeGenerationLink: window.location.href,
        })
        .then((res) => {
          this.resendDisable = false;
          this.$toasted.success(this.$t("code_sent_successfully"));
        })
        .catch((err) => {
          this.resendDisable = false;
          if (err?.response?.status == 429) {
            return this.$toasted.error(
              this.$t("account_auth_code_too_many_attempts")
            );
          }

          this.$toasted.error(this.$t("something_went_wrong"));
        });
    },
    async verifyCode() {
      this.loading = true;

      await this.$axios
        .post("dashboard/users/verify-2fa-code", { code: this.form.code })
        .then(async (res) => {
          this.loading = false;

          // refetching user
          await this.$store.dispatch(
            "auth/attempt",
            this.$store.getters["auth/authenticated"].mc_access_token
          );

          if (this.$store.getters["auth/user"].is_two_factor_auth) {
            return this.$router.push({ name: "members-list" });
          }
        })
        .catch((err) => {
          this.loading = false;

          if (
            err?.response?.status == 422 &&
            err?.response?.data?.errors?.code
          ) {
            if (
              err?.response?.data?.errors?.code ==
              "The selected code is invalid."
            ) {
              return (this.authCodeErrorMsg = this.$t("auth_code_wrong"));
            }
            if (
              err?.response?.data?.errors?.code ==
              "Verification code is Expired!"
            ) {
              this.authCodeErrorMsg = this.$t("auth_code_expired");
            }
          }
        });
    },
    async getAuthCodeLifeTime() {
      return await this.$axios
        .get("dashboard/users/2fa-code-lifetime")
        .then((res) => {
          this.authCodeLifeTime = res.data.data;
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/views/account-auth.scss"></style>
